import React from "react"
import placeholder from '../../static/assets/images/placeholder.jpg';
import Tracks from './tracks.js';

import joyful_0_img from '../../static/assets/images/joyful_0.png';
import joyful_1_img from '../../static/assets/images/joyful_1.jpg';
import joyful_2_img from '../../static/assets/images/joyful_2.jpg';
import joyful_3_img from '../../static/assets/images/joyful_3.jpg';
import joyful_4_img from '../../static/assets/images/joyful_4.jpg';
import joyful_5_img from '../../static/assets/images/joyful_5.jpg';
import joyful_6_img from '../../static/assets/images/joyful_6.png';

import glorious_0_img from '../../static/assets/images/glorious_0.png';
import glorious_1_img from '../../static/assets/images/glorious_1.jpg';
import glorious_2_img from '../../static/assets/images/glorious_2.jpg';
import glorious_3_img from '../../static/assets/images/glorious_3.jpg';
import glorious_4_img from '../../static/assets/images/glorious_4.jpg';
import glorious_5_img from '../../static/assets/images/glorious_5.jpg';
import glorious_6_img from '../../static/assets/images/glorious_6.png';

import luminous_0_img from '../../static/assets/images/luminous_0.png';
import luminous_1_img from '../../static/assets/images/luminous_1.jpg';
import luminous_2_img from '../../static/assets/images/luminous_2.jpg';
import luminous_3_img from '../../static/assets/images/luminous_3.jpg';
import luminous_4_img from '../../static/assets/images/luminous_4.jpg';
import luminous_5_img from '../../static/assets/images/luminous_5.jpg';
import luminous_6_img from '../../static/assets/images/luminous_6.png';

import sorrowful_0_img from '../../static/assets/images/sorrowful_0.png';
import sorrowful_1_img from '../../static/assets/images/sorrowful_1.jpg';
import sorrowful_2_img from '../../static/assets/images/sorrowful_2.jpg';
import sorrowful_3_img from '../../static/assets/images/sorrowful_3.jpg';
import sorrowful_4_img from '../../static/assets/images/sorrowful_4.jpg';
import sorrowful_5_img from '../../static/assets/images/sorrowful_5.jpg';
import sorrowful_6_img from '../../static/assets/images/sorrowful_6.png';
import transparent from '../../static/assets/images/transparent.png';

// const NoSleep = require('nosleep.js');

export default class AudioPlayer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            songIndex:0,
            image:null,
            title:''
        }
    }
    
    componentDidMount() {
        let type = this.props.type;
        console.log(type)
        let audioImages = {
            "Joyful":[
                joyful_6_img,
                joyful_0_img,
                joyful_1_img,
                joyful_2_img,
                joyful_3_img,
                joyful_4_img,
                joyful_5_img,
                joyful_0_img,
            ],
            "Glorious": [
                glorious_6_img,
                glorious_0_img,
                glorious_1_img,
                glorious_2_img,
                glorious_3_img,
                glorious_4_img,
                glorious_5_img,
                glorious_0_img,
            ],
            "Luminous": [
                luminous_6_img,
                luminous_0_img,
                luminous_1_img,
                luminous_2_img,
                luminous_3_img,
                luminous_4_img,
                luminous_5_img,
                luminous_0_img,
            ],
            "Sorrowful": [
                sorrowful_6_img,
                sorrowful_0_img,
                sorrowful_1_img,
                sorrowful_2_img,
                sorrowful_3_img,
                sorrowful_4_img,
                sorrowful_5_img,
                sorrowful_0_img,
            ]
                    
        }   
        let that = this;
        if (typeof window !== 'undefined') {
        setInterval(function() {
                let iframeElement   = document.querySelector('#soundcloud');
                if (iframeElement) {
                    let widget1         = window.SC.Widget(iframeElement);
                    console.log(window.SC)
                    widget1.getCurrentSoundIndex(function(song) {
                        that.setState({image:audioImages[type][song]});
                    })
                    widget1.getCurrentSound(function(song) {
                        console.log(song)
                        that.setState({title:song.title});
                    })
                }
        }, 1000)
        }   
    }

    render() {
        return (
            <>
            <div className='cover-container'>
                    <div className={'cover ' + this.props.type.toLowerCase()}>
                        <img className='cover-art' src={this.state.image}/>
                    <span className='song-title'>{this.state.title}</span>
                    </div>
                </div>
            <div className='audio-container'>
                <Tracks tracks={this.props.tracks} type={this.props.type}
                />
            </div>
            </>
        )
    }
  
}
