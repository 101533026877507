import React from "react"
import fb from '../../static/assets/images/fb.png'
import copy from '../../static/assets/images/copy.png'
// const SoundcloudWidget = require('soundcloud-widget')

export default class Tracks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isCopied:false,
            isWindow:(typeof window !== 'undefined') ? true : false,
            src:null
        }
    }

    getPlaylist(type) {
        switch(type.toLowerCase()) {
            case 'sorrowful':
                this.setState({ src:'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1014334627'})
                break;
            case 'joyful':
                this.setState({ src: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1014326587' })
                break;
            case 'luminous':
                this.setState({ src: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1014327946' })
                break;
            case 'glorious':
                this.setState({ src: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1014327328' })
                break;
        }        
    }

    componentDidMount() {
        this.getPlaylist(this.props.type);
    }

    // componentDidUpdate() {
    //     const iframe = 'soundcloud' // can also pass in an iframe node
    //     const widget = new SoundcloudWidget(iframe)
    //     widget.getCurrentSound(function(data) {
    //         console.log(data);
    //     })        
    // }

    render() {
        return (
            <div className='track-list-container'>
                {/* {
                    (this.props.tracks) ?
                        this.props.tracks.map((track, i) => {
                            return (
                                <div key={i} className="amplitude-song-container amplitude-play-pause" data-amplitude-playlist="mystery" data-amplitude-song-index={i}>
                                    {track}
                                </div>
                            )
                        }) :
                        <></>
                } */}
             <div style={{position:'relative'}}>
                <iframe style={{margin:'0'}}id="soundcloud" src={this.state.src +'&color=%236897fa&auto_play=false&hide_related=true&show_comments=false&show_user=false&show_reposts=false&show_teaser=false&sharing=false&show_artwork=true&download=false&buying=false&liking=false&show_playcount=false'} width="100%" height="450" scrolling="no" frameBorder="no" allow="autoplay"></iframe>
                <div className={'artist-blocker'}></div>
                <div className={'title-blocker'}></div>

             </div>
                <div className={'logo-blocker'} style={{
                    background: 'rgb(245,245,245)',
                    width: '110px',
                    height: '50px',
                    top: '2px',
                    position: 'absolute',
                    right: '2px',
                }}></div>
                <div className={'soundcloud-blocker'}></div>
                <div className="share-mystery">
                    <p>Share this mystery!</p>
                    <div className="share-options">
                        {
                            (this.state.isWindow) ?
                                <iframe src={`https://www.facebook.com/plugins/share_button.php?href=${encodeURIComponent(window.location.href)}&layout=button&size=small&width=67&height=20&appId`} width="67" height="20" style={{"border":"none","overflow":"hidden","margin":"0"}} scrolling="no" allow="encrypted-media"></iframe>
                                :<></>
                        }
                        <img 
                            onClick={
                                () => {
                                        this.setState({isCopied:true});
                                        const el = document.createElement('textarea');
                                        el.value = window.location.href;
                                        el.setAttribute('readonly', '');
                                        el.style.position = 'absolute';
                                        el.style.left = '-9999px';
                                        document.body.appendChild(el);
                                        el.select();
                                        document.execCommand('copy');
                                        document.body.removeChild(el);
                                        setTimeout(() => {
                                            this.setState({ isCopied: false });
                                        }, 3000)
                                }
                            }
                            src={copy} 
                            className="copy" />
                    </div>
                    <p
                        style={{
                            'visibility': (this.state.isCopied) ? 'visible' : 'hidden'
                        }}
                    >
                        Copied to clipboard!
                    </p>
                </div>
            </div> 
        )
    }
    
}