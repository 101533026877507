import React from "react";
import Header from '../components/header.js';
import Footer from '../components/footer.js';
import divider from '../../static/assets/images/Main-DottedDivider.png';
import AudioPlayer from '../components/audioPlayer.js';
import { Link } from "gatsby";
import '../components/mystery.min.css';
const SC = (typeof window !== 'undefined') && require('soundcloud-widget');

export default ( {data} ) => {
    let post = data.markdownRemark;
    // let allInsta = data.allInstaNode.edges.sort((a, b) => {
    //   return b.node.timestamp - a.node.timestamp;
    // })
    // let insta = allInsta.map((img) => {
    //   return img.node.localFile.childImageSharp.fluid;
    // }).slice(0,4)
    let tracks = [
      'Beginning Prayers',
      post.frontmatter.decade_1,
      post.frontmatter.decade_2,
      post.frontmatter.decade_3,
      post.frontmatter.decade_4,
      post.frontmatter.decade_5,
      'Ending Prayers'
    ]

    let type = post.frontmatter.title;
    return (
      <div>
        <Header />
        <div className="mystery wrapper-1080">
          <h2>{type} Mysteries</h2>
          <Link 
            style={{
              display:'block',
              textAlign:'center',
              fontStyle:'italic',
              fontSize:'18px',
              fontFamily:'Source Sans Regular'
            }} 
            to='/mysteries'>
              Back to all Mysteries
          </Link>
          <div className="image-divider" style={{ 'minHeight': '12px' }}>
            <img src={divider}  />
          </div>
          <p>{post.frontmatter.description}</p>
          <p>Please choose a track from the playlist below. You can pray the whole rosary all the way through by selecting "Full Rosary" or you can pray individual decades by clicking on any of the particular mysteries. Enjoy!</p>
          <div className="flex-container" style={{'marginTop': '60px'}}>
            <AudioPlayer tracks={tracks} type={type}/>
          </div>
          <div className="prayers-container">
            <h4>The Apostles' Creed</h4>
            <p>I believe in God, the Father Almighty, Creator of Heaven and earth; and in Jesus Christ, His
            only Son, Our Lord, Who was conceived by the Holy Spirit, born of the Virgin Mary, suffered
            under Pontius Pilate, was crucified, died, and was buried. He descended into hell; on the third
            day He rose again from the dead; He ascended into Heaven, and is seated at the right hand of
            God, the Father Almighty; from there He will come to judge the living and the dead. I believe in
            the Holy Spirit, the holy Catholic Church, the communion of saints, the forgiveness of sins, the
resurrection of the body, and life everlasting. Amen.</p>
            <h4>Our Father</h4>
            <p>Our Father, Who art in heaven, Hallowed be Thy Name.<br></br>
              Thy Kingdom come. Thy Will be done, on earth as it is in Heaven.<br></br>
              Give us this day our daily bread. And forgive us our trespasses, as we forgive those who trespass against us. <br></br>
              And lead us not into temptation, but deliver us from evil. Amen.
            </p>
            <h4>Hail Mary</h4>
            <p>Hail Mary, Full of Grace, The Lord is with thee.<br></br>
            Blessed art thou among women, and blessed is the fruit of thy womb, Jesus.<br></br>
          Holy Mary, Mother of God, pray for us sinners now, and at the hour of our death. Amen.</p>
            <h4>Glory Be</h4>
            <p>Glory be to the Father, and to the Son, and to the Holy Spirit.<br></br>
            As it was in the beginning, is now, and ever shall be, world without end.<br></br>
            Amen.</p>
            <h4>Fatima Prayer</h4>
            <p>O my Jesus, forgive us our sins, save us from the fires of hell, lead all souls to Heaven, especially those in most need of Thy mercy.</p>
            <h4>Hail, Holy Queen</h4>
            <p>
              Hail, holy Queen, mother of mercy, our life, our sweetness, and our hope. <br></br>
              To thee do we cry, poor banished children of Eve. To thee do we send up our sighs mourning and weeping in this valley of tears. <br></br>
              Turn then, most gracious advocate, thine eyes of mercy toward us, and after this our exile show us the blessed fruit of thy womb, Jesus.<br></br>
              O clement, O loving, O sweet Virgin Mary.<br></br><br></br>
              V- Pray for us oh Holy Mother of God.<br></br>
              R - They we may be made worthy of the promises of Christ.
            </p>
            <h4>Let us Pray</h4>
            <p>
              O God, whose only begotten Son, by His life, death, and resurrection, has purchased for us the<br></br>
              rewards of eternal life. Grant, we beseech Thee, that while meditating upon these mysteries of<br></br>
              the most holy Rosary of the Blessed Virgin Mary, we may imitate what they contain and obtain<br></br>
              what they promise, through the same Christ our Lord. Amen.
            </p>
        </div>
      </div>
        <Footer siteTitle={'On a Mission to Love'} />
    </div>
    )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        decade_1
        decade_2
        decade_3
        decade_4
        decade_5
        title
        description
      }
    }
  }
  
`